import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
  SidebarSpan,
  SidebarA,
} from "./SidebarElements";
import Flag from "react-world-flags";
import { changeLanguage } from "../../app/_actions/_actions";

const languages = [
  { value: "en", title: "English", code: "USA" },
  { value: "lv", title: "Latviešu", code: "LVA" },
  { value: "ru", title: "Русский", code: "RUS" },
  { value: "de", title: "Deutsch", code: "DEU" },
];

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuId: 1,
    };
    this.toggleManu = this.toggleManu.bind(this);
  }

  toggleManu(menuId) {
    this.setState({
      ...this.state,
      menuId: menuId,
    });
  }

  selectLang(value, title, code) {
    this.toggleManu(1);
    this.props.toggle();
    localStorage.setItem("language", value);
    localStorage.setItem("title", title);
    localStorage.setItem("code", code);
    this.props.changeLanguage(value);
  }

  render() {
    return (
      <>
        <SidebarContainer isOpen={this.props.isOpen}>
          <Icon
            onClick={() =>
              this.state.menuId === 1 ? this.props.toggle() : this.toggleManu(1)
            }
          >
            <CloseIcon />
          </Icon>

          <SidebarWrapper>
            <SidebarMenu>
              {this.state.menuId === 1 && (
                <>
                  {this.props.isContactPage && (
                    <>
                      <SidebarA href="/" style={{ marginRight: "27px" }}>
                        <FormattedMessage
                          id="Global.HomePage"
                          defaultMessage="Home Page"
                        />
                      </SidebarA>
                    </>
                  )}
                  {!this.props.isContactPage && (
                    <>
                      <SidebarLink
                        to="about"
                        onClick={() => this.props.toggle()}
                      >
                        <FormattedMessage
                          id="Global.AboutUs"
                          defaultMessage="About Us"
                        />
                      </SidebarLink>
                      <SidebarLink
                        to="services"
                        onClick={() => this.props.toggle()}
                      >
                        <FormattedMessage
                          id="Global.WhatWeOffer"
                          defaultMessage="What We Offer"
                        />
                      </SidebarLink>
                      <SidebarLink
                        to="vehicles"
                        onClick={() => this.props.toggle()}
                      >
                        <FormattedMessage
                          id="Global.AboutOurVehicles"
                          defaultMessage="AboutOurVehicles"
                        />
                      </SidebarLink>
                      <SidebarLink
                        to="drivers"
                        onClick={() => this.props.toggle()}
                      >
                        <FormattedMessage
                          id="Global.AboutOurDrivers"
                          defaultMessage="About Our Drivers"
                        />
                      </SidebarLink>
                    </>
                  )}
                  <SidebarSpan onClick={() => this.toggleManu(2)}>
                    <FormattedMessage
                      id="Global.ChangeLanguage"
                      defaultMessage="Change language"
                    />
                    <Flag
                      code={this.props.languageCode}
                      width="42"
                      height="26"
                      style={{ margin: "5px 0px 0px 10px" }}
                    />
                  </SidebarSpan>
                </>
              )}
              {this.state.menuId === 2 && (
                <>
                  {languages.map((language, i) => {
                    return (
                      <div
                        key={i}
                        style={
                          language.value === "en" || language.value === "ru"
                            ? { marginRight: "23px" }
                            : language.value === "de"
                            ? { marginRight: "12px" }
                            : { marginRight: "0px" }
                        }
                      >
                        <SidebarLink
                          to="home"
                          onClick={() =>
                            this.selectLang(
                              language.value,
                              language.title,
                              language.code
                            )
                          }
                          fontWeight={
                            language.code === this.props.languageCode ? 1 : 0
                          }
                        >
                          <Flag
                            code={language.code}
                            width="42"
                            height="26"
                            style={{ padding: "4px 10px 0px 0px" }}
                          />
                          {language.title}
                        </SidebarLink>
                      </div>
                    );
                  })}
                </>
              )}
            </SidebarMenu>
            {this.state.menuId === 1 && !this.props.isContactPage && (
              <SideBtnWrap>
                <SidebarRoute to="/contact-us">
                  {" "}
                  <FormattedMessage
                    id="Global.СontactWithUs"
                    defaultMessage="Сontact With Us"
                  />
                </SidebarRoute>
              </SideBtnWrap>
            )}
          </SidebarWrapper>
        </SidebarContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  languageCode: state.settings.preferences.code,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (langId) => dispatch(changeLanguage(langId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
