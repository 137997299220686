import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";
import {Link} from 'react-scroll';

export const PageNotFoundWrap = styled.div`
  width: 100%;
  max-width: 50rem;
  background-color: white;
  padding: 2.5rem;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.8);

  @media screen and (max-width: 320px) {
    width: 300px;
    padding: 25px 12px 25px 12px;
  }
`;

export const LogoWrap = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
`;

export const Image = styled.img`
  width: 100%;
`;

export const H2 = styled.h2`
  color: #fff;
  font-size: 3.6rem;
  text-transform: uppercase;
`;

export const H3 = styled.h3`
  display: inline-block;
  background-color: #fff;
  font-size: 2rem;
  text-transform: uppercase;
  padding: 1rem;
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-right: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-right: 8px;
  font-size: 20px;
`;

export const Button = styled(Link)`
 border-radius: 50px;
 background: ${({primary}) => (primary ? '#F9A826' : '#010606')};
 white-space: nowrap;
 padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
 color: ${({dark}) => (dark ? '#010606' : '#fff')};
 font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
 outline: none;
 border: none;
 cursor: pointer;
 display: flex;
 justify-content: center;
 align-items: center;
 transition: all 0.2s ease-in-out;

 &:hover {
    transition: all 0.2s ease-in-out;
    background: black;
    color: #fff;
 }
`