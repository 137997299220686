import React, { useState, useEffect } from 'react'
import {
    Slider,
    Image,
    LeftArrow,
    RightArrow
} from "./SliderElements";
import { SliderData } from './SliderData';

const ImageSlider = ({ slides }) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    }

    useEffect(() => {
        let timerFunc = setTimeout(() => {
            setCurrent(current === 0 ? length - 1 : current - 1);
        }, 5000);

        return () => {
            clearTimeout(timerFunc);
        };
    }, [current, length]);

    if (!Array.isArray(slides) || slides.length <= 0) {
        return null;
    }

    return (
        <Slider>
            {window.innerWidth >= 1000 &&
                <>
                    <LeftArrow onClick={prevSlide} />
                    <RightArrow onClick={nextSlide} />
                </>
            }
            {SliderData.map((slide, index) => {
                return (
                    <div className={index === current ? 'slide active' : 'slide'} key={index}>
                        {index === current && (
                            <Image src={slide.image} alr="st auto truck image" />
                        )}
                    </div>
                )
            })}
        </Slider>
    )
}

export default ImageSlider
