import React, { Component } from "react";
import Video from "../../videos/video.mp4";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
} from "../HeroSection/HeroElement";
import notFound from "../../images/page_not_found.svg";
import {
  PageNotFoundWrap,
  LogoWrap,
  Image,
  H3,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  Button
} from "./PageNotFountElements";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { FormattedMessage } from "react-intl";

class PageNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      hover: false,
      isOpen: false,
    };
    this._isMounted = false;
    this.onHover = this.onHover.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("resize", this.resize.bind(this));
    if (this._isMounted) {
      this.resize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
    this._isMounted = false;
  }

  setIsOpen() {
    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen,
    });
  }

  resize() {
    let isMobile = window.innerWidth <= 1000;
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile: isMobile });
    }
  }

  onHover() {
    this.setState({ ...this.state, hover: !this.state.hover });
  }

  render() {
    return (
      <>
        <Sidebar
          isContactPage
          isMobile={this.state.isMobile}
          isOpen={this.state.isOpen}
          toggle={() => this.setIsOpen()}
        />
        <Navbar
          isContactPage
          isMobile={this.state.isMobile}
          toggle={() => this.setIsOpen()}
        />
        <HeroContainer id="home">
          <HeroBg>
            <VideoBg
              autoPlay={true}
              loop={true}
              controls={false}
              playsInline
              muted
              src={Video}
              type="video/mp4"
            />
          </HeroBg>
          <HeroContent>
            <PageNotFoundWrap>
              <LogoWrap>
                <Image src={notFound} alt="404 not found" />
              </LogoWrap>
              <H3>
                <FormattedMessage
                  id="Global.404ErrorText"
                  defaultMessage="The page you seek does not exist"
                />
              </H3>
              <HeroBtnWrapper>
                <Button
                  to="/"
                  onMouseEnter={() => this.onHover()}
                  onMouseLeave={() => this.onHover()}
                  onClick={() =>  window.location.href = "/"}
                  primary="false"
                  dark="false"
                >
                  <FormattedMessage
                    id="Global.HomePage"
                    defaultMessage="Home Page"
                  />
                  {this.state.hover ? <ArrowForward /> : <ArrowRight />}
                </Button>
              </HeroBtnWrapper>
            </PageNotFoundWrap>
          </HeroContent>
        </HeroContainer>
      </>
    );
  }
}

export default PageNotFound;
