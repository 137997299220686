import React from "react";
import { FaBars, FaCaretDown, FaCaretUp } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  NavSpan,
  ArrowLeft,
  NavArrowLeft,
} from "./NavbarElements";
import logo from "../../images/STauto.png";
import Flag from "react-world-flags";
import { connect } from "react-redux";
import { changeLanguage } from "../../app/_actions/_actions";
import NavbarDropdown from "react-navbar-dropdown";
import "../../dropdown.scss";

const languages = [
  { value: "en", title: "English", code: "USA" },
  { value: "lv", title: "Latviešu", code: "LVA" },
  { value: "ru", title: "Русский", code: "RUS" },
  { value: "de", title: "Deutsch", code: "DEU" },
];

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollNav: false,
    };
    this.toggleHome = this.toggleHome.bind(this);
    this.changeNav = this.changeNav.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.changeNav);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeNav);
  }

  changeNav() {
    if (window.scrollY >= 80) {
      this.setState({
        ...this.state,
        scrollNav: true,
      });
    } else {
      this.setState({
        ...this.state,
        scrollNav: false,
      });
    }
  }

  toggleHome() {
    scroll.scrollToTop();
  }

  selectLang(value, title, code) {
    localStorage.setItem("language", value);
    localStorage.setItem("title", title);
    localStorage.setItem("code", code);
    this.props.changeLanguage(value);
  }

  render() {
    return (
      <>
        <IconContext.Provider value={{ color: "#fff" }}>
          <Nav scrollNav={this.state.scrollNav}>
            {this.props.isContactPage && !this.props.isMobile && (
              <ArrowLeft to="/" onClick={() => this.toggleHome()}>
                <NavArrowLeft />
              </ArrowLeft>
            )}
            <NavbarContainer>
              <NavLogo to="/" onClick={() => this.toggleHome()}>
                <img src={logo} alt="ST AUTO" height={60} />
              </NavLogo>
              <MobileIcon onClick={() => this.props.toggle()}>
                <FaBars />
              </MobileIcon>
              <NavMenu isContactPage={this.props.isContactPage}>
                <NavItem>
                  <NavLinks
                    to="about"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                  >
                    <FormattedMessage
                      id="Global.AboutUs"
                      defaultMessage="About Us"
                    />
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to="services"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                  >
                    <FormattedMessage
                      id="Global.WhatWeOffer"
                      defaultMessage="What We Offer"
                    />
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to="vehicles"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                  >
                    <FormattedMessage
                      id="Global.AboutOurVehicles"
                      defaultMessage="AboutOurVehicles"
                    />
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to="drivers"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                  >
                    <FormattedMessage
                      id="Global.AboutOurDrivers"
                      defaultMessage="About Our Drivers"
                    />
                  </NavLinks>
                </NavItem>
              </NavMenu>
              <NavBtn isContactPage={this.props.isContactPage}>
                <NavBtnLink to="/contact-us">
                  {" "}
                  <FormattedMessage
                    id="Global.СontactWithUs"
                    defaultMessage="Сontact With Us"
                  />
                </NavBtnLink>
              </NavBtn>
              <NavBtn>
                <NavbarDropdown className="menu__div">
                  <NavbarDropdown.Toggle className="menu__item">
                    <div style={{ display: "flex", cursor: "pointer" }}>
                      <NavbarDropdown.Open>
                        <Flag
                          code={this.props.languageCode}
                          width="32"
                          height="32"
                        />
                        <NavSpan style={{ marginTop: "5px" }}>
                          {this.props.languageTitle}
                        </NavSpan>
                        <FaCaretDown style={{ marginTop: "5px" }} />
                      </NavbarDropdown.Open>
                      <NavbarDropdown.Close>
                        <Flag
                          code={this.props.languageCode}
                          width="32"
                          height="32"
                        />
                        <NavSpan style={{ marginTop: "5px" }}>
                          {this.props.languageTitle}
                        </NavSpan>
                        <FaCaretUp style={{ marginTop: "5px" }} />
                      </NavbarDropdown.Close>
                    </div>
                  </NavbarDropdown.Toggle>
                  <NavbarDropdown.CSSTransitionMenu
                    className="example1-dropdown-menu"
                    classNames="example1-dropdown-menu"
                    timeout={200}
                    style={{ alignItems: "flex-start" }}
                  >
                    {languages.map((language, i) => {
                      return (
                        <NavbarDropdown.Item
                          key={i}
                          className="example1-dropdown-menu-item"
                          onClick={() =>
                            this.selectLang(
                              language.value,
                              language.title,
                              language.code
                            )
                          }
                        >
                          <div>
                            <Flag
                              code={language.code}
                              width="42"
                              height="26"
                              style={{ padding: "4px 10px 0px 0px" }}
                            />
                          </div>
                          <div className="example1-dropdown-menu-item__spacer" />
                          <div className="example1-dropdown-menu-item__text">
                            {" "}
                            {language.title}
                          </div>
                        </NavbarDropdown.Item>
                      );
                    })}
                  </NavbarDropdown.CSSTransitionMenu>
                </NavbarDropdown>
              </NavBtn>
            </NavbarContainer>
          </Nav>
        </IconContext.Provider>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  languageCode: state.settings.preferences.code,
  languageTitle: state.settings.preferences.title,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (langId) => dispatch(changeLanguage(langId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
