import React, { PureComponent } from "react";
import { injectIntl } from "react-intl";
import { Button } from "../ButtonElements";
import ImageSlider from "../Slider/ImageSlider";
import { SliderData } from "../Slider/SliderData";
import {
    BtnWrap,
    Column1,
    Column2,
    Heading,
    Img,
    ImgWrap,
    InfoContainer,
    InfoRow,
    InfoWrapper,
    Subtitle,
    TextWrapper,
    TopLine,
} from "./InfoElements";

class InfoSection extends PureComponent {
    render() {
        const { intl } = this.props;
        const headline = this.props.headlineId;
        const descriptionId = this.props.descriptionId;
        const topLineId = this.props.topLineId;
        return (
            <>
                <InfoContainer lightBg={this.props.lightBg} id={this.props.id}>
                    <InfoWrapper>
                        <InfoRow imgStart={this.props.imgStart}>
                            <Column1>
                                <TextWrapper>
                                    {this.props.topLineId &&
                                        <TopLine>{intl.formatMessage({
                                            id: topLineId
                                        })}</TopLine>
                                    }
                                    {this.props.headlineId &&
                                        <Heading lightText={this.props.lightText}>{intl.formatMessage({
                                            id: headline
                                        })}</Heading>
                                    }
                                    {this.props.descriptionId &&
                                        <Subtitle darkText={this.props.darkText}>
                                            {intl.formatMessage({
                                                id: descriptionId
                                            })}
                                        </Subtitle>
                                    }
                                    {this.props.showButton &&
                                        <BtnWrap>
                                            <Button to='home'
                                                smooth={true}
                                                duration={500}
                                                spy={true}
                                                exact='true'
                                                offset={-80}
                                                primary={this.props.primary ? 1 : 0}
                                                dark={this.props.dark ? 1 : 0}
                                                dark2={this.props.dark2 ? 1 : 0}
                                            >
                                                {this.props.buttonLabel}
                                            </Button>
                                        </BtnWrap>
                                    }
                                </TextWrapper>
                            </Column1>
                            <Column2>
                                <ImgWrap>
                                    {this.props.showSlider ? (
                                        <ImageSlider slides={SliderData} />
                                    ) : (
                                        <Img src={this.props.img} alt={this.props.alt} />
                                    )}
                                </ImgWrap>
                            </Column2>
                        </InfoRow>
                    </InfoWrapper>
                </InfoContainer>
            </>
        )
    }
}

export default injectIntl(InfoSection);
