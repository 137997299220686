import React from "react";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialMediaLogo,
  WebsiteRights,
  MarkerIcon,
  PhoneIcon,
  MobileIcon,
  EnvelopeIcon,
  SocialMediaLink,
  Rights,
  RightsWrap,
  FaxIcon
} from "./FooterElement";
import { animateScroll as scroll } from "react-scroll";
import logo from "../../images/STauto.png";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialMediaLogo to="/" onClick={toggleHome}>
              <img src={logo} alt="ST AUTO" height={60} />
            </SocialMediaLogo>
            <SocialMediaLink
              href="https://goo.gl/maps/X4Y78TJLBp3wQPvC6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MarkerIcon />
              Jūrkalnes iela 6, Rīga, LV-1046
            </SocialMediaLink>
            <SocialMediaLink href="tel: +37129206447">
              <MobileIcon />
              +371 29206447
            </SocialMediaLink>
            <SocialMediaLink href="tel: +37167871416">
              <PhoneIcon />
              +371 67871416
            </SocialMediaLink>
            <SocialMediaLink href="tel: +37167871407">
              <FaxIcon />
              +371 67871407
            </SocialMediaLink>
            <SocialMediaLink href="mailto: info@stauto.lv">
              <EnvelopeIcon />
              info@stauto.lv
            </SocialMediaLink>
          </SocialMediaWrap>
        </SocialMedia>
        <Rights>
          <RightsWrap>
            <WebsiteRights>
              st auto © {new Date().getFullYear()} All rights reserved.
            </WebsiteRights>
          </RightsWrap>
        </Rights>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
