export const homeObjOne = {
    id: "about",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLineId: "Global.AboutUs",
    headlineId: "Global.AboutUsHeadline",
    descriptionId: "Global.AboutUsDescription",
    showButton: false,
    imgStart: true,
    img: require('../../images/svg-3.svg'),
    alt: "Car",
    dark: false,
    primary: false,
    darkText: true,
    showSlider: false
};

export const homeObjTwo = {
    id: "vehicles",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLineId: "Global.AboutOurVehicles",
    headlineId: undefined,
    descriptionId: "Global.VehiclesInfo",
    showButton: false,
    buttonLabel: "Get started",
    imgStart: false,
    img: require('../../images/svg-1.svg'),
    alt: "Car",
    dark: false,
    primary: false,
    darkText: true,
    showSlider: true
};

export const homeObjThree = {
    id: "drivers",
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLineId: "Global.AboutOurDrivers",
    headlineId: undefined,
    descriptionId: "Global.DriverInfo",
    showButton: false,
    buttonLabel: "Get started",
    imgStart: true,
    img: require('../../images/svg-9.svg'),
    alt: "Car",
    dark: true,
    primary: true,
    darkText: false,
    showSlider: false
};
