import styled from 'styled-components';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';

export const Slider = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 50px;
  
  @media screen and (max-width: 1090px) {
    margin-left: 0;
  }
`

export const Image = styled.img`
    width: 500px;
    height: 333px;
    margin: 0 0 50px 0;
    padding-right: 0;
    border-radius: 10px;
    
    @media screen and (max-width: 1300px) {
        width: 400px;
    }

    @media screen and (max-width: 1090px) {
        width: 300px;
        height: 233px;
    }

    @media screen and (max-width: 1000px) {
        width: 500px;
        height: 333px;
    }

    @media screen and (max-width: 650px) {
        width: 300px;
        height: 233px;
    }
`

export const RightArrow = styled(FaArrowAltCircleRight)`
   position: absolute;
   top: 145px;
   left: 500px;
   font-size: 3rem;
   color: #F9A826;
   cursor: pointer;
   user-select: none;

   @media screen and (max-width: 1300px) {
    left: 420px;
    top: 115px;
   }

   @media screen and (max-width: 1090px) {
      left: 385px;
      top: 75px;
   }

   @media screen and (max-width: 945px) {
    left: 375px;
   }

   @media screen and (max-width: 1000px) {
      left: 525px;
   }
`
export const LeftArrow = styled(FaArrowAltCircleLeft)`
   position: absolute;
   top: 145px;
   right: 500px;
   font-size: 3rem;
   color: #F9A826;
   cursor: pointer;
   user-select: none;

   @media screen and (max-width: 1300px) {
    right: 420px;
    top: 115px;
   }

   @media screen and (max-width: 1090px) {
    right: 385px;
    top: 75px;
   }

   @media screen and (max-width: 945px) {
    right: 375px;
   }

   @media screen and (max-width: 1000px) {
    right: 525px;
   }

`