export const SliderData = [
    {
        image: require('../../images/slider-picture-1.JPG')
    },
    {
        image: require('../../images/slider-picture-2.jpg')
    },
    {
        image: require('../../images/slider-picture-3.jpg')
    },
    {
        image: require('../../images/slider-picture-4.jpg')
    }
]