import React from "react";
import { FormattedMessage } from "react-intl";
import cargo from "../../images/svg-5.svg";
import delivery from "../../images/svg-8.svg";
import deal from "../../images/svg-7.svg";
import {
  ServicesCard,
  ServicesContainer,
  ServicesH1,
  ServicesH2,
  ServicesP,
  ServicesWrapper,
  ServicesIconLeft,
  ServicesIconCenter,
  ServicesIconRight
} from "./ServicesElements";
import { connect } from "react-redux";

class Services extends React.Component {
  render() {
    return (
      <ServicesContainer id="services">
        <ServicesH1>
          <FormattedMessage
            id="Global.WhatWeOffer"
            defaultMessage="What We Offer"
          />
        </ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIconLeft src={cargo} language={this.props.language} />
            <ServicesH2>
              <FormattedMessage
                id="Global.LoadingOfTrucks"
                defaultMessage="Loading of trucks"
              />
            </ServicesH2>
            <ServicesP>
              <FormattedMessage
                id="Global.LoadingInfo"
                defaultMessage="Loading your cargo either as a separately loaded vehicle (only your cargo) or as a bundle-loaded vehicle (your cargo bundled with other clients)"
              />
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIconCenter
              src={delivery}
              language={this.props.language}
            />
            <ServicesH2>
              <FormattedMessage
                id="Global.CarriageOfGoods"
                defaultMessage="Carriage of goods"
              />
            </ServicesH2>
            <ServicesP>
              <FormattedMessage
                id="Global.CarriageInfo"
                defaultMessage="The list of our destinations includes many popular European countries and cities (by land or water), including, but not limited to: Germany, Belgium, Netherlands, Switzerland, Luxemburg, Baltic states, Poland"
              />
            </ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIconRight src={deal} language={this.props.language} />
            <ServicesH2>
              <FormattedMessage
                id="Global.Paperwork"
                defaultMessage="Paperwork"
              />
            </ServicesH2>
            <ServicesP>
              <FormattedMessage
                id="Global.PaperworkInfo"
                defaultMessage="Our experts will help you with documents, customs, storage"
              />
            </ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.settings.preferences.language,
});

export default connect(mapStateToProps, null)(Services);
