import React from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import HeroSection from '../components/HeroSection';
import Services from '../components/Services';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjTwo, homeObjThree } from '../components/InfoSection/Data';
import Footer from '../components/Footer';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.setIsOpen = this.setIsOpen.bind(this);
    }

    setIsOpen() {
        this.setState({
            ...this.state,
            isOpen: !this.state.isOpen
        })
    }

    render() {
        return (
            <>
                <Sidebar isOpen={this.state.isOpen} toggle={() => this.setIsOpen()} />
                <Navbar toggle={() => this.setIsOpen()} />
                <HeroSection />
                <InfoSection {...homeObjOne} />
                <Services />
                <InfoSection {...homeObjTwo} />
                <InfoSection {...homeObjThree} />
                <Footer />
            </>
        )
    }
}

export default Home;
