import React, { Component } from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import ru from "react-intl/locale-data/ru";
import lv from "react-intl/locale-data/lv";
import de from "react-intl/locale-data/de";
import localeData from "../src/translations/data.json";
import { connect } from "react-redux";
import Home from "./pages";
import "./App.css";
import { Route, Switch } from "react-router";
import ContactUs from "./pages/contactUs";
import PageNotFound from "./components/NotFoundPage/PageNotFound";

addLocaleData([...en, ...ru, ...de, ...lv]);

class App extends Component {
  render() {
    const language = this.props.language || "en";
    const messages = localeData[language];
    return (
      <IntlProvider locale={language} messages={messages}>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/contact-us" component={ContactUs} exact />
          <Route component={PageNotFound} />
        </Switch>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.settings.preferences.language,
});

export default connect(mapStateToProps, null, null, { pure: false })(App);