import { settingsActionTypes } from "../_actions/_actions";

const initialState = {
    preferences: {
        language: localStorage.getItem("language") || "en",
        title: localStorage.getItem("title") || "English",
        code: localStorage.getItem("code") || "USA",
    }
};

export function settings(state = initialState, action) {
    switch (action.type) {
        case settingsActionTypes.CHANGE_LANGUAGE:
            var title = state.title;
            var code = state.code;

            switch (action.language) {
                case "en":
                    title = "English";
                    code = "USA";
                    break;
                case "ru":
                    title = "Русский";
                    code = "RUS"
                    break;
                case "lv":
                    title = "Latviešu";
                    code = "LVA";
                    break;
                case "de":
                    title = "Deutsch";
                    code = "DEU";
                    break;
                default:
                    title = "English";
                    code = "USA";
            }
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    language: action.language,
                    title: title,
                    code: code
                }
            }
        default:
            return state;
    };
}
