import React, { useState } from "react";
import Video from "../../videos/video.mp4";
import { Button } from "../ButtonElements";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElement";
import { FormattedMessage } from "react-intl";

const HeroSection = () => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg
          autoPlay={true}
          loop={true}
          controls={false}
          playsInline
          muted
          src={Video}
          type="video/mp4"
        />
      </HeroBg>
      <HeroContent>
        <HeroH1>
          <FormattedMessage
            id="Global.CompanyName"
            defaultMessage='SIA \"ST AUTO\"'
          />
        </HeroH1>
        <HeroP>
          <FormattedMessage
            id="Global.HeroSectionInfo"
            defaultMessage="Is a reliable partner in the realm of cargo transportation with 15+ years of experience and with its own cargo transport auto-park"
          />
        </HeroP>
        <HeroBtnWrapper>
          <Button
            to="about"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            <FormattedMessage id="Global.ReadMore" defaultMessage="Read More" />
            {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
