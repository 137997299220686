import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaMapMarkerAlt, FaPhoneAlt, FaMobileAlt, FaRegEnvelope, FaFax, FaSkype } from 'react-icons/fa';

export const MarkerIcon = styled(FaMapMarkerAlt)`
   color: #fff;
   font-size: 24px;
   margin-right: 5px;
`

export const PhoneIcon = styled(FaPhoneAlt)`
   color: #fff;
   font-size: 24px;
   margin-right: 5px;
`

export const FaxIcon = styled(FaFax)`
   color: #fff;
   font-size: 24px;
   margin-right: 5px;
`

export const MobileIcon = styled(FaMobileAlt)`
   color: #fff;
   font-size: 24px;
   margin-right: 5px;
`

export const EnvelopeIcon = styled(FaRegEnvelope)`
   color: #fff;
   font-size: 24px;
   margin-right: 5px;
`

export const SkypeIcon = styled(FaSkype)`
   color: #fff;
   font-size: 24px;
   margin-right: 5px;
`

export const FooterContainer = styled.footer`
 background-color: #101522;
`

export const FooterWrap = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 max-width: 1100px;
 margin: 0 auto;

 @media screen and (max-width: 1000px) {
   padding: 48px 24px;
 }
`
export const FooterLinksContainer = styled.div`
 display: flex;
 justify-content: center;

 @media screen and (max-width: 820px) {
    padding-top: 32px;
 }
`
export const FooterLinksWrapper = styled.div`
 display: flex;

 @media screen and (max-width: 820px) {
    flex-direction: column;
 }
`

export const FooterLinkItems = styled.div`
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 margin: 16px;
 text-align: left;
 width: 160px;
 box-sizing: border-box;
 color: #fff;

 @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
 }
`

export const FooterLinkTitle = styled.h1`
 font-size:14px;
 margin-bottom: 16px;
`

export const FooterLink = styled.a`
 color: #fff;
 text-decoration: none;
 margin-bottom: 0.5rem;
 font-size: 14px;

 &:hover {
     color: #F9A826;
     transition: 0.3s ease-out;
 }
`

export const SocialMedia = styled.section`
 max-width: 1000px;
 width: 100%;
`
export const Rights = styled.section`
 max-width: 1000px;
`

export const SocialMediaWrap = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 max-width: 1100px;
 margin: 20px 0px 0px 0px;

 @media screen and (max-width: 820px) {
    flex-direction: column;
 }
`
export const RightsWrap = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   max-width: 1100px;

   @media screen and (max-width: 820px) {
      flex-direction: column;
   }
`;

export const SocialMediaLogo = styled(Link)`
 color: #fff;
 justify-self: start;
 cursor: pointer;
 text-decoration: none;
 font-size: 1.5rem;
 display: flex;
 align-items: center;
 margin-bottom: 16px;
 font-weight: bold;
`
export const SocialMediaLink = styled.a`
 color: #fff;
 justify-self: start;
 cursor: pointer;
 text-decoration: none;
 font-size: 14px;
 display: flex;
 align-items: center;
 margin-bottom: 16px;
 
 &:hover {
     color: #F9A826;
     transition: 0.3s ease-out;
 }
`

export const SocialMediaInfo = styled.span`
 color: #fff;
 justify-self: start;
 text-decoration: none;
 font-size: 14px;
 display: flex;
 align-items: center;
 margin-bottom: 16px;
`
export const WebsiteRights = styled.small`
 color: #fff;
 margin-bottom: 16px;
`
export const FooterInfo = styled.small`
 color: #fff;
 text-decoration: none;
 font-size: 12px;
`

export const SocialIcons = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 240px;
`
export const SocialIconLink = styled.a`
 color: #fff;
 font-size: 24px;
`

export const FooterDiv = styled.div`
   width: 100%;
`