import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaFax,
  FaInfo,
  FaInstagram,
  FaLinkedin,
  FaMapMarkerAlt,
  FaMobile,
  FaPhoneAlt,
  FaRegEnvelope,
  FaSkype,
  FaTwitter,
} from "react-icons/fa";

export const Container = styled.div`
  min-height: 692px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background-image: url("https://images.pexels.com/photos/1003868/pexels-photo-1003868.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
`;

export const FormWrap = styled.div`
  height: 88%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 400px) {
    height: 80%;
  }
  margin-top: 100px;
`;

export const Icon = styled(Link)`
  margin-left: 32px;
  margin-top: 32px;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 32px;

  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;
export const ContactContainer = styled.div`
  margin: 0 auto;
  width: 1200px;
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
  display: flex;
  backdrop-filter: blur(25px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0px 10px 33px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 33px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 33px 0px rgba(0, 0, 0, 0.75);
  @media screen and (max-width: 1210px) {
    width: 1000px;
    margin-top: 40px;
  }
`;

export const ContactInfo = styled.div`
  background: linear-gradient(
    108deg,
    rgba(1, 147, 86, 1) 0%,
    rgba(232, 126, 4, 1) 100%
  );
  border-radius: 10px;
  flex: 0 1 65%;
  padding: 40px;
  color: white;
  display: flex;
  flex-direction: column;
`;

export const ContactUsH4 = styled.h4`
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
  margin-bottom: 2rem;
`;
export const ContactUsP = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 2rem;
`;

export const IconText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
`;

export const WebFaMapMarkerAlt = styled(FaMapMarkerAlt)`
  flex: 0 0 20px;
  font-size: 1.8rem;
  margin-right: 2rem;
  color: #f9a826;
`;

export const WebFaInfo = styled(FaInfo)`
  flex: 0 0 20px;
  font-size: 1.8rem;
  margin-right: 2rem;
  color: #f9a826;
`;

export const WebFaPhoneAlt = styled(FaPhoneAlt)`
  flex: 0 0 20px;
  font-size: 1.8rem;
  margin-right: 2rem;
  color: #f9a826;
`;

export const WebFaMobile = styled(FaMobile)`
  flex: 0 0 20px;
  font-size: 1.8rem;
  margin-right: 2rem;
  color: #f9a826;
`;

export const WebFaRegEnvelope = styled(FaRegEnvelope)`
  flex: 0 0 20px;
  font-size: 1.8rem;
  margin-right: 2rem;
  color: #f9a826;
`;

export const WebFaSkype = styled(FaSkype)`
  flex: 0 0 20px;
  font-size: 1.8rem;
  margin-right: 2rem;
  color: #f9a826;
`;

export const WebFaFax = styled(FaFax)`
  flex: 0 0 20px;
  font-size: 1.8rem;
  margin-right: 2rem;
  color: #f9a826;
`;

export const WebFaFacebook = styled(FaFacebook)`
  font-size: 25px;
`;

export const WebFaTwitter = styled(FaTwitter)`
  font-size: 25px;
`;

export const WebFaInstagram = styled(FaInstagram)`
  font-size: 25px;
`;

export const WebFaLinkedin = styled(FaLinkedin)`
  font-size: 25px;
`;

export const ContactUsSpan = styled.span`
  font-size: 1.3rem;
  font-weight: 300;
`;

export const ContactUsA = styled.a`
  font-size: 1.3rem;
  font-weight: 300;
  color: white;
  text-decoration: none;
  &:hover {
    color: #f9a826;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
`;

export const IconCircle = styled.span`
  flex: 0 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 50%;
  color: white;
  transition: 0.3s all ease-in-out;
  &:hover {
    background: #f9a826;
  }
`;

export const ContactUsForm = styled.form`
  padding: 40px;
  width: 70%;
`;

export const ContactUsDiv = styled.div`
  margin-bottom: 45px;
`;

export const ContactUsH1 = styled.h1`
  text-align: center;
  color: #fff;
  font-size: 2rem;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
  font-weight: 500;
`;

export const Col = styled.div`
  display: flex;
  width: 100%;
  flex: 0 0 100%;
  margin-bottom: 1rem;
`;
export const FormGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 0 1 100%;
  padding-right: 40px;
  &.solo {
    padding: 0;
  }

  &.right {
    align-items: flex-end;
  }

  &:nth-last-child(2) {
    padding-right: 0;
  }
`;

export const ContactUsLabel = styled.label`
  font-size: 0.9;
  color: rgba(252, 255, 255, 0.8);
  margin-bottom: 1rem;
`;

export const ContactUsInput = styled.input`
  background: rgba(252, 255, 255, 0.2);
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 1.5rem;
  font-weight: 300;
  color: white;
  background: transparent;
  border-bottom: ${({ isInvalid }) =>
    isInvalid ? "2px solid red" : "2px solid rgba(252, 255, 255, 0.4)"};
  transition: all 0.3s ease-in-out;
  &:focus {
    border: none;
    outline: none;
    border-bottom: 2px solid #f9a826;
  }
  resize: none;
`;

export const ContactUsTextarea = styled.textarea`
  background: rgba(252, 255, 255, 0.2);
  border: none;
  cursor: pointer;
  padding: 10px;
  width: 93%;
  font-size: 1.5rem;
  font-weight: 300;
  color: white;
  background: transparent;
  border-bottom: ${({ isInvalid }) =>
    isInvalid ? "2px solid red" : "2px solid rgba(252, 255, 255, 0.4)"};
  transition: all 0.3s ease-in-out;
  &:focus {
    border: none;
    outline: none;
    border-bottom: 2px solid #f9a826;
  }
  resize: none;
`;

export const ContactUsButton = styled.button`
  width: 145px;
  height: 60px;
  cursor: pointer;
  background: ${({ isMobile }) => (isMobile ? "#F9A826" : "#fff")};
  border: none;
  text-transform: uppercase;
  border-radius: 5px;
  color: ${({ isMobile }) => (isMobile ? "#fff" : "black")};
  &:hover {
    background: #f9a826;
    transition: 0.3s ease-out;
  }
`;

export const FormGroupP = styled.p`
  font-size: 12px;
  color: red;
  font-weight: ${({ isMobile }) => (isMobile ? "0" : "600")};
`;

export const FormGroupDiv = styled.div`
  margin-top: ${({ isMobile }) => (isMobile ? "0px" : "5px")};
  height: 15px;
`;

export const ContactUsMobilePageWrapper = styled.div`
  background: url("https://images.pexels.com/photos/1003868/pexels-photo-1003868.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
`;

export const ContactUsMobileContact = styled.section`
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    padding: 50px;
    padding-top: 100px;
  }
`;

export const ContactUsMobileContent = styled.div`
  text-align: center;
  max-width: 800px;
`;

export const ContactUsMobileH2 = styled.h2`
  font-size: 25px;
  font-weight: 500;
  color: #fff;
`;

export const ContactUsMobileP = styled.p`
  font-weight: 300;
  color: #fff;
`;

export const ContactUsMobileA = styled.a`
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-weight: 300;
  &:hover {
    color: #f9a826;
    transition: 0.3s ease-out;
  }
`;

export const ContactUsMobileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const ContactUsMobileContactInfo = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    margin-bottom: 40px;
    width: 100%;
  }
`;

export const ContactUsMobileBox = styled.div`
  position: relative;
  padding: 20px 0;
  display: flex;
`;

export const ContactUsMobileIcon = styled.div`
  min-width: 60px;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
  color: #f9a826;
`;

export const ContactUsMobileText = styled.div`
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
`;

export const ContactUsMobileH3 = styled.h3`
  font-weight: 500;
  color: #f9a826;
`;

export const ContactUsMobileContactForm = styled.div`
  width: 40%;
  padding: 40px;
  background: #fff;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const MobileContactFormH2 = styled.h2`
  font-size: 30px;
  color: #333;
  font-weight: 500;
`;

export const MobileContactFormInputBox = styled.h2`
  font-size: 30px;
  color: #333;
  font-weight: 500;
`;

export const MobileInputBox = styled.div`
  position: relative;
  width: 100%;
  margin-top: 30px;
`;

export const MobileInput = styled.input`
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
  &:focus ~ span,
  &:valid ~ span {
    font-size: 16px;
    transform: translateY(-20px);
  }
`;

export const MobileTextarea = styled.textarea`
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;

  &:focus ~ span,
  &:valid ~ span {
    font-size: 16px;
    transform: translateY(-20px);
  }
`;

export const MobileSpan = styled.span`
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
`;
