import React from "react";
import {
  Container,
  FormContent,
  FormWrap,
  ContactContainer,
  ContactInfo,
  ContactUsH4,
  IconText,
  WebFaMapMarkerAlt,
  WebFaPhoneAlt,
  WebFaMobile,
  WebFaRegEnvelope,
  ContactUsSpan,
  ContactUsA,
  ContactUsForm,
  ContactUsDiv,
  ContactUsH1,
  Col,
  FormGroup,
  ContactUsLabel,
  ContactUsInput,
  ContactUsTextarea,
  ContactUsButton,
  FormGroupP,
  FormGroupDiv,
  ContactUsMobilePageWrapper,
  ContactUsMobileContact,
  ContactUsMobileContent,
  ContactUsMobileH2,
  ContactUsMobileP,
  ContactUsMobileA,
  ContactUsMobileContainer,
  ContactUsMobileContactInfo,
  ContactUsMobileBox,
  ContactUsMobileIcon,
  ContactUsMobileText,
  ContactUsMobileH3,
  ContactUsMobileContactForm,
  MobileContactFormH2,
  MobileInputBox,
  MobileInput,
  MobileTextarea,
  MobileSpan,
  WebFaFax,
  WebFaInfo,
} from "./ContactUsElements";
import {
  FaMapMarkerAlt,
  FaMobile,
  FaRegEnvelope,
  FaFax,
  FaInfo,
  FaCreditCard,
} from "react-icons/fa";
import * as emailjs from "emailjs-com";
import toast from "react-hot-toast";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { FormattedMessage } from "react-intl";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      message: "",
      email: "",
      phone: "",
      fullName: "",
      errors: {
        phoneInvalid: false,
        emailInvalid: false,
        messageInvalid: false,
        fullNameInvalid: false,
      },
      isOpen: false,
    };
    this._isMounted = false;

    this.setIsOpen = this.setIsOpen.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeFullName = this.handleChangeFullName.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("resize", this.resize.bind(this));
    if (this._isMounted) {
      this.resize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
    this._isMounted = false;
  }

  setIsOpen() {
    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen,
    });
  }

  resize() {
    let isMobile = window.innerWidth <= 1000;
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile: isMobile });
    }
  }

  handleChangeMessage(event) {
    this.setState({
      ...this.state,
      message: event.target.value,
      errors: {
        ...this.state.errors,
        messageInvalid: false,
      },
    });
  }

  handleChangeEmail(event) {
    this.setState({
      ...this.state,
      email: event.target.value,
      errors: {
        ...this.state.errors,
        emailInvalid: false,
      },
    });
  }

  handleChangePhone(event) {
    this.setState({
      ...this.state,
      phone: event.target.value,
      errors: {
        ...this.state.errors,
        phoneInvalid: false,
      },
    });
  }

  handleChangeFullName(event) {
    this.setState({
      ...this.state,
      fullName: event.target.value,
      errors: {
        ...this.state.errors,
        fullNameInvalid: false,
      },
    });
  }

  sendMessage(e) {
    e.preventDefault();
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    this.setState({
      ...this.state,
      errors: {
        ...this.state.errors,
        emailInvalid: !pattern.test(this.state.email) ? true : false,
        phoneInvalid: this.state.phone.trim().length === 0 ? true : false,
        messageInvalid: this.state.message.trim().length === 0 ? true : false,
        fullNameInvalid: this.state.fullName.trim().length === 0 ? true : false,
      },
    });
    var isValid =
      pattern.test(this.state.email) &&
      this.state.message.trim().length > 0 &&
      this.state.fullName.trim().length > 0 &&
      this.state.phone.trim().length > 0;

    if (isValid) {
      //For test uncomment this:
      //toast.success("Message was sent successfully");
      //And comment text below
      var data = {
        to_email: "info@stauto.lv",
        message: this.state.message,
        phone: this.state.phone,
        email: this.state.email,
        fullName: this.state.fullName,
      };
      this.setState({
        message: "",
        email: "",
        phone: "",
        fullName: "",
      });
      emailjs
        .send(
          "service_0ikqfx5",
          "template_0etv0um",
          data,
          "user_iJRxdxhkfMP7RTRX2ivRU"
        )
        .then(
          function () {
            toast.success("Message was sent successfully");
          },
          function (err) {
            toast.error("Message wasn't sent to ST AUTO");
            console.log(err);
          }
        );
    }
    // //For test uncomment this:
    // else {
    //   toast.error("Message wasn't sent to ST AUTO");
    // }
  }

  render() {
    return (
      <>
        {this.state.isMobile ? (
          <ContactUsMobilePageWrapper>
            <Sidebar
              isContactPage
              isMobile
              isOpen={this.state.isOpen}
              toggle={() => this.setIsOpen()}
            />
            <Navbar isContactPage isMobile toggle={() => this.setIsOpen()} />
            <ContactUsMobileContact>
              <ContactUsMobileContent>
                <ContactUsMobileH2>
                  <FormattedMessage
                    id="Global.ContactInformation"
                    defaultMessage="Contact Information"
                  />
                </ContactUsMobileH2>
              </ContactUsMobileContent>
              <ContactUsMobileContainer>
                <ContactUsMobileContactInfo>
                  <ContactUsMobileBox>
                    <ContactUsMobileIcon>
                      <FaInfo />
                    </ContactUsMobileIcon>
                    <ContactUsMobileText>
                      <ContactUsMobileP>
                        <ContactUsMobileP style={{ marginTop: "10px" }}>
                          ST AUTO, SIA <br />
                          LV40003717429
                        </ContactUsMobileP>
                      </ContactUsMobileP>
                    </ContactUsMobileText>
                  </ContactUsMobileBox>
                  <ContactUsMobileBox>
                    <ContactUsMobileIcon>
                      <FaMapMarkerAlt />
                    </ContactUsMobileIcon>
                    <ContactUsMobileText>
                      <ContactUsMobileH3>
                        <FormattedMessage
                          id="Global.Address"
                          defaultMessage="Address"
                        />
                      </ContactUsMobileH3>
                      <ContactUsMobileP>
                        <ContactUsMobileA
                          href="https://goo.gl/maps/X4Y78TJLBp3wQPvC6"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Jūrkalnes iela 6, Rīga, LV-1046
                        </ContactUsMobileA>
                      </ContactUsMobileP>
                    </ContactUsMobileText>
                  </ContactUsMobileBox>
                  <ContactUsMobileBox>
                    <ContactUsMobileIcon>
                      <FaMobile />
                    </ContactUsMobileIcon>
                    <ContactUsMobileText>
                      <ContactUsMobileH3>
                        <FormattedMessage
                          id="Global.PhoneNumber"
                          defaultMessage="Phone Number"
                        />
                      </ContactUsMobileH3>
                      <ContactUsMobileA href="tel:+37129206447">
                        +371 29206447,
                      </ContactUsMobileA>
                      <ContactUsMobileA href="tel:+37167871416">
                        +371 67871416
                      </ContactUsMobileA>
                    </ContactUsMobileText>
                  </ContactUsMobileBox>
                  <ContactUsMobileBox>
                    <ContactUsMobileIcon>
                      <FaFax />
                    </ContactUsMobileIcon>
                    <ContactUsMobileText>
                      <ContactUsMobileH3>Fax</ContactUsMobileH3>
                      <ContactUsMobileA href="tel:+37167871407">
                        +371 67871407
                      </ContactUsMobileA>
                    </ContactUsMobileText>
                  </ContactUsMobileBox>
                  <ContactUsMobileBox>
                    <ContactUsMobileIcon>
                      <FaRegEnvelope />
                    </ContactUsMobileIcon>
                    <ContactUsMobileText>
                      <ContactUsMobileH3>
                        <FormattedMessage
                          id="Global.Email"
                          defaultMessage="Email"
                        />
                      </ContactUsMobileH3>
                      <ContactUsMobileA href="mailto: info@stauto.lv">
                        info@stauto.lv
                      </ContactUsMobileA>
                    </ContactUsMobileText>
                  </ContactUsMobileBox>
                  <ContactUsMobileBox>
                    <ContactUsMobileIcon>
                      <FaCreditCard />
                    </ContactUsMobileIcon>
                    <ContactUsMobileText>
                      <ContactUsMobileP style={{ marginTop: "5px" }}>
                        SWEDBANK
                        <br />
                        SWIFT: HABALV22
                        <br />
                        IBAN: LV85 HABA 0551 0089 2694 9
                      </ContactUsMobileP>
                    </ContactUsMobileText>
                  </ContactUsMobileBox>
                </ContactUsMobileContactInfo>
                <ContactUsMobileContactForm>
                  <form>
                    <MobileContactFormH2>
                      <FormattedMessage
                        id="Global.СontactWithUs"
                        defaultMessage="Сontact With Us"
                      />
                    </MobileContactFormH2>
                    <MobileInputBox>
                      <MobileInput
                        value={this.state.fullName}
                        isInvalid={this.state.errors.fullNameInvalid}
                        onChange={(e) => this.handleChangeFullName(e)}
                        required
                      />
                      <MobileSpan>
                        <FormattedMessage
                          id="Global.FullName"
                          defaultMessage="Full Name"
                        />
                      </MobileSpan>
                      <FormGroupDiv isMobile>
                        {this.state.errors.fullNameInvalid && (
                          <FormGroupP isMobile>
                            <FormattedMessage
                              id="Global.ErrorMessage"
                              defaultMessage="This field is required"
                            />
                          </FormGroupP>
                        )}
                      </FormGroupDiv>
                    </MobileInputBox>
                    <MobileInputBox>
                      <MobileInput
                        value={this.state.phone}
                        isInvalid={this.state.errors.phoneInvalid}
                        onChange={(e) => this.handleChangeEmail(e)}
                        required
                      />
                      <MobileSpan>
                        <FormattedMessage
                          id="Global.PhoneNumber"
                          defaultMessage="Phone Number"
                        />
                      </MobileSpan>
                      <FormGroupDiv isMobile>
                        {this.state.errors.phoneInvalid && (
                          <FormGroupP isMobile>
                            <FormattedMessage
                              id="Global.ErrorMessage"
                              defaultMessage="This field is required"
                            />
                          </FormGroupP>
                        )}
                      </FormGroupDiv>
                    </MobileInputBox>
                    <MobileInputBox>
                      <MobileInput
                        value={this.state.email}
                        isInvalid={this.state.errors.emailInvalid}
                        onChange={(e) => this.handleChangeEmail(e)}
                        required
                      />
                      <MobileSpan>
                        <FormattedMessage
                          id="Global.Email"
                          defaultMessage="Email"
                        />
                      </MobileSpan>
                      <FormGroupDiv isMobile>
                        {this.state.errors.emailInvalid && (
                          <FormGroupP isMobile>
                            <FormattedMessage
                              id="Global.ErrorMessage"
                              defaultMessage="This field is required"
                            />
                          </FormGroupP>
                        )}
                      </FormGroupDiv>
                    </MobileInputBox>
                    <MobileInputBox>
                      <MobileTextarea
                        value={this.state.message}
                        isInvalid={this.state.errors.messageInvalid}
                        onChange={(e) => this.handleChangeMessage(e)}
                        required
                      ></MobileTextarea>
                      <MobileSpan>
                        <FormattedMessage
                          id="Global.Message"
                          defaultMessage="Message"
                        />
                      </MobileSpan>
                      <FormGroupDiv isMobile>
                        {this.state.errors.messageInvalid && (
                          <FormGroupP isMobile>
                            <FormattedMessage
                              id="Global.ErrorMessage"
                              defaultMessage="This field is required"
                            />
                          </FormGroupP>
                        )}
                      </FormGroupDiv>
                    </MobileInputBox>
                    <MobileInputBox>
                      <ContactUsButton
                        isMobile
                        onClick={(e) => this.sendMessage(e)}
                      >
                        <FormattedMessage
                          id="Global.SendMessage"
                          defaultMessage="Send Message"
                        />
                      </ContactUsButton>
                    </MobileInputBox>
                  </form>
                </ContactUsMobileContactForm>
              </ContactUsMobileContainer>
            </ContactUsMobileContact>
          </ContactUsMobilePageWrapper>
        ) : (
          <Container>
            <FormWrap>
              <Sidebar
                isContactPage
                isOpen={this.state.isOpen}
                toggle={() => this.setIsOpen()}
              />
              <Navbar isContactPage toggle={() => this.setIsOpen()} />
              <FormContent>
                <ContactContainer>
                  <ContactInfo>
                    <ContactUsH4>
                      <FormattedMessage
                        id="Global.ContactInformation"
                        defaultMessage="Contact Information"
                      />
                    </ContactUsH4>
                    <IconText>
                      <WebFaInfo />
                      <ContactUsSpan>
                        ST AUTO, SIA <br />
                        LV40003717429
                      </ContactUsSpan>
                    </IconText>
                    <IconText>
                      <WebFaMapMarkerAlt />
                      <ContactUsA
                        href="https://goo.gl/maps/X4Y78TJLBp3wQPvC6"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Jūrkalnes iela 6, Rīga, LV-1046
                      </ContactUsA>
                    </IconText>
                    <IconText>
                      <WebFaPhoneAlt />
                      <ContactUsA href="tel: +37129206447">
                        +371 29206447
                      </ContactUsA>
                    </IconText>
                    <IconText>
                      <WebFaMobile />
                      <ContactUsA href="tel: +37167871416">
                        +371 67871416
                      </ContactUsA>
                    </IconText>
                    <IconText>
                      <WebFaFax />
                      <ContactUsA href="tel: +37167871407">
                        +371 67871407
                      </ContactUsA>
                    </IconText>
                    <IconText>
                      <WebFaRegEnvelope />
                      <ContactUsA href="mailto: info@stauto.lv">
                        info@stauto.lv
                      </ContactUsA>
                    </IconText>
                    <IconText>
                      <ContactUsSpan>
                        SWEDBANK
                        <br />
                        SWIFT: HABALV22
                        <br />
                        IBAN: LV85 HABA 0551 0089 2694 9
                      </ContactUsSpan>
                    </IconText>
                  </ContactInfo>
                  <ContactUsForm>
                    <ContactUsDiv>
                      <ContactUsH1>
                        <FormattedMessage
                          id="Global.СontactWithUs"
                          defaultMessage="Сontact With Us"
                        />
                      </ContactUsH1>
                    </ContactUsDiv>
                    <Col>
                      <FormGroup>
                        <ContactUsLabel>
                          <FormattedMessage
                            id="Global.FullName"
                            defaultMessage="Full Name"
                          />
                        </ContactUsLabel>
                        <ContactUsInput
                          type="text"
                          value={this.state.fullName}
                          isInvalid={this.state.errors.fullNameInvalid}
                          onChange={(e) => this.handleChangeFullName(e)}
                        />
                        <FormGroupDiv>
                          {this.state.errors.fullNameInvalid && (
                            <FormGroupP>
                              <FormattedMessage
                                id="Global.ErrorMessage"
                                defaultMessage="This field is required"
                              />
                            </FormGroupP>
                          )}
                        </FormGroupDiv>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <ContactUsLabel>
                          <FormattedMessage
                            id="Global.PhoneNumber"
                            defaultMessage="Phone Number"
                          />
                        </ContactUsLabel>
                        <ContactUsInput
                          type="phone"
                          value={this.state.phone}
                          isInvalid={this.state.errors.phoneInvalid}
                          onChange={(e) => this.handleChangePhone(e)}
                        />
                        <FormGroupDiv>
                          {this.state.errors.phoneInvalid && (
                            <FormGroupP>
                              <FormattedMessage
                                id="Global.ErrorMessage"
                                defaultMessage="This field is required"
                              />
                            </FormGroupP>
                          )}
                        </FormGroupDiv>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <ContactUsLabel>
                          <FormattedMessage
                            id="Global.Email"
                            defaultMessage="Email"
                          />
                        </ContactUsLabel>
                        <ContactUsInput
                          type="email"
                          value={this.state.email}
                          isInvalid={this.state.errors.emailInvalid}
                          onChange={(e) => this.handleChangeEmail(e)}
                        />
                        <FormGroupDiv>
                          {this.state.errors.emailInvalid && (
                            <FormGroupP>
                              <FormattedMessage
                                id="Global.ErrorMessage"
                                defaultMessage="This field is required"
                              />
                            </FormGroupP>
                          )}
                        </FormGroupDiv>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="solo">
                        <ContactUsLabel>
                          <FormattedMessage
                            id="Global.Message"
                            defaultMessage="Message"
                          />
                        </ContactUsLabel>
                        <ContactUsTextarea
                          value={this.state.message}
                          isInvalid={this.state.errors.messageInvalid}
                          onChange={(e) => this.handleChangeMessage(e)}
                        ></ContactUsTextarea>
                        <FormGroupDiv>
                          {this.state.errors.messageInvalid && (
                            <FormGroupP>
                              <FormattedMessage
                                id="Global.ErrorMessage"
                                defaultMessage="This field is required"
                              />
                            </FormGroupP>
                          )}
                        </FormGroupDiv>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="solo right">
                        <ContactUsButton onClick={(e) => this.sendMessage(e)}>
                          <FormattedMessage
                            id="Global.SendMessage"
                            defaultMessage="Send Message"
                          />
                        </ContactUsButton>
                      </FormGroup>
                    </Col>
                  </ContactUsForm>
                </ContactContainer>
              </FormContent>
            </FormWrap>
          </Container>
        )}
      </>
    );
  }
}

export default ContactPage;
