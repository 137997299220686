import React from "react";
import ScrollToTop from "../components/ScrollToTop";
import ContactPage from "../components/ContactUsPage";
import { Toaster } from "react-hot-toast";

class ContactUs extends React.Component {
  render() {
    return (
      <>
        <ScrollToTop />
        <Toaster position="top-right" reverseOrder={false} />
        <ContactPage />
      </>
    );
  }
}

export default ContactUs;
